const media = {
    //IMAGES

    paris2024_logo: require("./imgs/paris2024_logo.svg").default,

    flag_en: require("./imgs/flag_en.svg").default,
    flag_fr: require("./imgs/flag_fr.svg").default,
    flag_es: require("./imgs/flag_es.svg").default,

    overlay_img: require("./imgs/overlay_img.png"),

    home_banner: require("./imgs/home_banner.jpg"),
    home_product1: require("./imgs/home_product1.png"),
    home_product2: require("./imgs/home_product2.png"),
    home_product3: require("./imgs/home_product3.png"),
    home_product4: require("./imgs/home_product4.png"),
    home_product5: require("./imgs/home_product5.png"),

    apm_img1: require("./imgs/apm_img1.png"),
    apm_img2: require("./imgs/apm_img2.png"),

    relief_img1: require("./imgs/relief_img1.png"),
    relief_img2: require("./imgs/relief_img2.png"),

    cwalkt_img1: require("./imgs/cwalkt_img1.png"),
    cwalkt_img2: require("./imgs/cwalkt_img2.png"),

    cwalks_img1: require("./imgs/cwalks_img1.png"),
    cwalks_img2: require("./imgs/cwalks_img2.png"),

    yoshe_img1: require("./imgs/yoshe_img1.png"),
    yoshe_img2: require("./imgs/yoshe_img2.png"),
}

export default media;